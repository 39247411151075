@import "@fortawesome/fontawesome-free/css/all.css";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

@import "variables";

body {
  background: $white;
  color: $black;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

/* That dark / light theme thingy */

.light {
  background: $white;
  color: $black;
  transition: ease-in-out 0.25s;
}

.dark {
  background: $dark;
  color: $white;
  transition: ease-in-out 0.25s;

  #fichado, #sinfichar {
    background: unset;
  }

  #modal-invite {
    background: $black;
  }

  .iconos {
    .fa-twitter, .fa-phone, .fa-envelope {
      color: $white;
    }
  }

  input, textarea {
    background: $dark;
    color: $white;
  }

  #login, #register {
    background: transparentize($dark, 0.1);
  }
}

/* Vamos a definir de manera general los contenedores header, main y footer con
to' lo suyo! */

header, main, footer {
  padding: 0 $padding;
}

/* HEADER */

header {
  background: $black;
  color: $white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  img {
    height: 64px;
  }

}

/* NAV */

nav {
  margin-left: auto;
  
  a {
    text-decoration: none;
    font-weight: 600;
    color: $white;
    padding: 1.2rem;
    margin: 0;
    font-size: 1.1em;
    transition: 0.3s;

    &:hover {
      background: $black-hover;
      transition: 0.3s;
    }
  }
}

.visible, .invisible {
  display: flex;
}

#admin {
  text-decoration: none;
  font-weight: 600;
  color: $white;
  padding: 1.2rem;
  margin: 0;
  transition: 0.3s;
  font-size: 1.2em;
  display: block;

  &:hover {
    background: $black-hover;
    transition: 0.3s;
  }
}

#header-app {
  display: flex;
  flex-flow: row;
  flex-grow: 2;
  align-items: center;
  justify-content: space-between;
  padding: 0 12%;

  .perfil {
    position: relative;
    width: 52px;
  }
}

#imagen-app a {
  margin: auto;
}

.middle {
  margin: 0 auto;
}

.right {
  margin-left: auto;
}

#profile {
  max-width: 48px;
  max-height: 48px;
  object-fit:cover;
  border-radius: 50%;
  border: 2px solid #FAFAFA00;

  &:hover {
    transition: 0.3s;
    border: 2px solid #FAFAFA;
  }
}

#lista {
  display: none;
}

/* MAIN */

main {
  //min-height: 100vh;
  display: flex;
  flex-flow: column;
  flex: 0 1;
  max-width: 1920px;
  margin: auto;
}

/* Queremos tener la pantalla siempre al máximo posible pero sin esas barritas?
done. */

#no-overflow {
  overflow: hidden;
}

/* Amo a pone esa imagen rechulona atrá y cambiante */

#background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.85;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  
  img {
    height: 100vh;
  }
}

/* Login / register */

#login, #register {
  margin: 20vh 15vw auto auto;
  padding: 1em;
  background: #fdfdfdaa;
  border-radius: 0.5em;
}

.error {
  color: $red;
  background: $lightred;
  border-radius: 0.25em;
  margin: 0.5em 0;
  padding: 0.75em;
}

#term-cond {
  margin-left: 0.5em;
}

/*Amo a pone el form bonito iyo*/

form {
  width: 25vw;
  min-width: 300px;
  max-width: 600px;

  a {
    color: $primary-color;
    text-decoration: none;
    font-size: 0.9rem;

    &:hover {
      transition: 0.2s;
      color: $primary-color-hover;
      text-decoration: underline;
    }
  }
}

h1 {
  width: 100%;
  text-align: center;
}

label {
  font-size: 0.9em;
  text-align: left;
}

/* Inputs */

input[type="text"], input[type="password"], input[type="time"], textarea {
  margin: 0.2em 0 0.5em;
  padding: 0.5em;
  width: 95%;
  font-size: 1em;
  outline: 0;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid $black;
  border-radius: 0.25em;
}

textarea {
  resize: none;
}

input[type="password"] {
  margin-bottom: 0;
}

input[type="checkbox"] {
  margin: 0;
  margin-top: 1rem;
}

/*Botone'*/

.boton, input[type="submit"], button {
  background: $primary-color;
  text-decoration: none;
  color: $white;
  font-size: 1.1em;
  font-weight: 600;
  border: none;
  padding: 1em 2em;
  border-radius: 0.2em;
  margin-top: 1em;
  width: 99%;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    background: $primary-color-hover;
  }
}

/* eso' span */

div {
  span {
    font-size: 0.9em;
    margin-top: 1em;
  }
}

/* MAIN (el de verdá) */

/* INDEX */

#portada, .container-row {
  display: flex;
  flex-flow: row;
  flex: 1 1;
}

#portada {
  margin-bottom: 1em;
  min-height: 100vh;
  padding: 2em;
  margin: 1em;

  .container-column {
    flex: 0 1 350px;
    padding: 2em;
    align-items: center;
    margin: auto;
  }

  .container-row {
    height: fit-content;
    margin: auto;
    align-items: center;
  }

  .boton {
    text-align: center;
    width: 80%;
  }

  img {
    width: 60vw;
    min-height: initial;
    height: auto;
    margin-left: auto;
    max-width: 1000px;
  }
}

.container-column {
  display: flex;
  flex-flow: column;
  flex: 1 1;
}

#contenido {
  /* max-width: 1400px; */
  width: 100%;
  margin: auto;
  text-align: justify;
}

section {
  img {
    width: 450px;
  }
}

article {
  margin: 1em;
  padding: 2em;

  .container-column {
    flex: 1 1 450px;
    max-width: 450px;
    margin: 1em;
    margin-right: auto;
    padding: 1em;
  }

  &:nth-child(2n) {
    flex-flow: row-reverse;
    margin: 1em;
    margin-left: auto;
    padding: 2em;

    .container-column {
      flex: 1 1 450px;
      max-width: 450px;
      margin: 1em;
      margin-left: auto;
    }
  }

  &:last-child {
    margin-bottom: 1.5em;
  }
}

/* CONTACTO */

#informacion, #contactanos, #contacto, #iconos {
  display: flex;
  flex-flow: column;
  margin: auto;
  max-width: 750px;
  text-align: justify;
}

#informacion {
  img {
    margin: auto;
    width: 220px;
  }

  p {
    font-size: 1.2em;
    padding: 1rem;
  }
}

#contacto {
  margin-top: 2em;
  margin-bottom: 2.5em;
}

#iconos {
  flex-flow: row;
}

.fa-envelope, .fa-phone, .fa-twitter {
  text-align: center;
  font-size: 1.3em;
  margin: 0.25em 0.5em;
  cursor: pointer;
  color: $black;
  text-decoration: none;
}

/* TERMINOS Y CONDICIONES */

#term, #cond {
  margin: 2em auto;
  margin-bottom: 0;
  max-width: 1000px;
  text-align: justify;

  a {
    color: $primary-color;
    text-decoration: none;
    font-size: 0.9rem;
    //margin: 0.5em;

    &:hover {
      transition: 0.2s;
      color: $primary-color-hover;
      text-decoration: underline;
    }
  }
}

/* APP */

#app {
  min-height: unset;
}

#sinfichar, #fichado {
  display: flex;
  margin: 10vh auto;
  max-width: calc(50vw + 3em);
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 1em 2em;
  background: #fdfdfdaa;
  border-radius: 0.5em;
}

#app {

  h1 {
    margin-bottom: 1.5em;
  }

  .boton {
    max-width: 260px;
    margin: 1.5em auto;
  }
}

#fichado {
  .container-row {
    flex-flow: row;
    width: fit-content;
    margin: 1em auto;
  }
}

.botoncir {
  padding: 1em;
  width: 1em;
  width: 1em;
  height: 1em;
  transition: all 0.2s ease-in-out;
  color: #1b1f24;
  margin: 1em;
  cursor: pointer;

  &:hover:not(.selected) {
    color: #fdfdfd;
    background: #ff5722;
    border: 1em;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
  }
}

.fa-mug-hot, .fa-pause, .fa-utensils {
  display: block;
  margin: auto;
}

.selected {
  color: #fdfdfd;
  background: #ff4408;
  border: 1em;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.not-selected {
  padding: 1em;
}

#table {
  margin: auto;
  width: 80%;
  margin-bottom: 2em;
}

.botones-tabla {
  min-height: fit-content;
  margin-bottom: 0.5em;
  cursor: pointer;

  a {
    text-decoration: none;
    //color: $black;
    padding: 0.25em 1em;
    border-bottom: 5px solid #00000000;
  }

  .btn-selected {
    font-weight: bold;
    border-bottom: 5px solid $primary-color-hover;
    transition: 0.35s;
  }
}

table {
  width: 100%;
  border: 1px solid $gray;
  //border-radius: 0.25rem;
  th, tr, td {
    padding: 1.2em;
  }
  
  tr {
    width: 50%;
    height: fit-content;
    
    th {
      width: fit-content;
      text-align: left;
      font-weight: bold;
    }
  }
  
}

/* PERFIL */

#perfil-cont{
  padding: 2em $padding;
  .container-row {
    margin: auto;
    width: 80%;
    justify-items: center;

    #img-cont {
      margin-top: 1rem;
      margin-right: 5em;
    }

    img {
      height: 160px;
      width: 160px;
      object-fit: cover;
      border-radius: 50%;
      flex: unset;
    }
  }

  .container-column {
    width: fit-content;
    flex: unset;
    
    .label {
      margin: 0.75em 0;
      font-weight: 600;
      width: fit-content;
    }

    p {
      margin: 0;
      width: fit-content;
      //margin-top: auto;
    }
    
    #userNombre {
      font-size: 1.2em;
      font-weight: 600;
      padding: 1rem;
      margin: 0;
    }

    #nombre-cont {
      width: 100%;
      align-items: center;
    }

    #role {
      margin: 0;
      align-items: center;
      
      select {
        font-size: 1.05em;
      }
      
      span {
        margin: unset;
        cursor: pointer;
        color: $primary-color;
        margin-left: 1em;

        &:hover {
          color: $primary-color-hover;
        }
      }   
    }

    #other-data {
      width: 100%;
      justify-content: space-between;
      flex-flow: row wrap;

      .container-column {
        width: 33%;
        padding: 0.25em 1em;
      }

      
    }
  }  
}

.btn {
  display: block;
  font-size: 0.9rem;
  width: fit-content;
  padding: 0.7rem 1.25rem;
  margin: 2rem auto;
}

.danger {
  background: transparentize($danger, 0.2);
  color: $white;

  &:hover {
    background: opacify($danger, 0.2);
  }
}

.opc-fichar {
  margin: 0.5rem;
  cursor: pointer;
  transition: color 0.15s;
  font-size: 1.1rem;

  &:hover {
    transition: color 0.15s;
    color: $primary-color;
  }
}

/*Asminnnnnnnnnn*/

#admin-header {
  justify-content: space-between;
  max-width: 1250px;
  width: 100%;
  margin: auto;

  h1 {
    width: fit-content;
    text-align: left;
    margin: 1rem;
  }

  .btn {
    margin: 1rem;
  }
}

#cont-admin {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1250px;
  margin: auto;

  .user {
    flex: 1 1 500px;
    max-width: 550px;
    border: 1px solid $gray;
    border-radius: 0.25em;
    margin: 1em;
    cursor: pointer;
    position: relative;

    img {
      margin: 1em;
      height: 90px;
      width: 90px;
      object-fit: cover;
      border-radius: 50%;
      flex: unset;
    }
  
    .container-column {
      flex: unset;
      width: fit-content;
      margin: auto 1em;

      p {
        width: fit-content;
        margin: 0.5em;
      }
    }
  }
}

#menu {
  display: none;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  z-index: 2;
  background: $dark;
  border-radius: 0 0 0.25em 0.25em;
  width: max-content;

  &::after {
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    content: "";
    border: 10px solid transparent;
    border-bottom: 10px solid $dark;
    z-index: 3;
    left: auto;
    right: 16px;
    top: -20px;
  }

  a {
    color: $white;
    text-decoration: none;
    margin: 0.25rem;
    padding: 0.5em 1.2em;

    &:hover {
      background: $black;
    }
  }
}

/* EDITAAAAAAAAR */
#editar {
  max-width: 1200px;
  width: 100%;
  margin: auto;

  h2 {
    margin: 0;
    height: fit-content;
  }

  h3 {
    margin: 0;
  }

  article {
    align-items: center;
    margin: auto;
    padding: 1rem;

    #userImage {
      margin: 1em;
      height: 160px;
      width: 160px;
      object-fit: cover;
      border-radius: 50%;
      flex: unset;
    }

    .container-column {
      height: fit-content;
      width: fit-content;
      margin: 0 2em;

      form {
        width: fit-content;
        min-width: unset;
        max-width: unset;
      }

      label {
        cursor: pointer;
        font-weight: 600;
        color: $primary-color;
        &:hover {
          text-decoration: underline;
        }
      }

      span {
        cursor: pointer;
        font-weight: 600;
        color: $danger;

        &:hover {
          text-decoration: underline;
        }
      }
    }

  }

  form {
    display: flex;
    flex-flow: column;
    margin-top: 1em;
  }

  label {
    margin: 0.5em 0;
    font-weight: 600;
    width: fit-content;
  }

  #theme {
    justify-content: space-between;

    .container-row {
      justify-content: space-between;
      align-items: center;
    }

    label {
      display: block;
      width: fit-content;
      //margin-right: auto;
    }
    // no se puede cambiar los radios :)
    // https://stackoverflow.com/questions/4253920/how-do-i-change-the-color-of-radio-buttons/38468320
    input {
      display: block;
      width: 1.2em;
      height: 1.2em;
    }
  }
}

#modal-container {
  display: flex;
  flex-direction: column;
  flex: 0 1; 
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  background: transparentize($dark, 0.8);
}

#modal-invite {
  width: fit-content;
  max-height: fit-content;
  margin: auto;
  flex: unset;
  background: $white;
  padding: 1em;
  border-radius: 0.5em;
  position: relative;

  #cerrar {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.2em 1em;
    font-size: 1.3em;
    color: $primary-color;
    cursor: pointer;

    &:hover {
      color: $primary-color-hover;
    }
  }

  .container-column {
    flex: inherit;
    height: fit-content;
  }

  h2 {
    margin-top: 0;
  }

  #loading, #sent, #nosent {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    margin: auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: inherit;
    border-radius: inherit;
    
    h2 {
      margin-top: 1em;
    }

    .fa-check-circle, .fa-clock, .fa-times-circle {
      color: $primary-color;
      font-size: 2em;
    }
  }

}

#genloading {
  display: flex;
  flex-direction: column;
  flex: 0 1; 
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;

  div {
    margin: auto;
    color: $primary-color;
    font-size: 3em;
  }
}

/* FOOTER */

footer {
  background: $black;
  color: $white;
  font-size: 0.85rem;
  text-align: center;
  padding: 1em;

  a {
    color: $primary-color;
    text-decoration: none;
    font-size: 0.9rem;
    margin: 0.5em;

    &:hover {
      transition: 0.2s;
      color: $primary-color-hover;
      text-decoration: underline;
    }
  }

  p {
    margin: 0.5em;

  }
}

.fa-code {
  color: $code;
}

.fa-heart {
  color: $heart;
}

@media only screen and (max-width: 1200px) {
  #login, #register {
    margin: auto;
  }
}

@media only screen and (max-width: 1000px) {
  /* INDEX */
  #portada {
    min-height: unset;

    .container-column {
      padding: 0;
    }
  }

  article:nth-child(2n), #portada, .container-row {
    flex-flow: column wrap;
    padding: 0;
    margin: 1em;
  }

  #opc-cont {
    .container-row {
      flex-flow: row nowrap;
    }
  }

  article:nth-child(2n) .container-column, article .container-column, section img {
    flex: 1 0;
    margin: 0;
    padding: 0;
    margin-bottom: 1.2em;
    width: 100%;
    max-width: unset;
  }

  #portada img {
    width: 100%;
  }

  section img {
    width: 100%;
  }

  #contenido {
    margin-bottom: 1.2em;
  }

  #perfil-cont {
    
    .container-row {
      flex-flow: row;
    }

    #img-cont {
      img {
        margin: 0 auto;
      }
    }

    .container-column {
      width: 100%;
      padding: 0;

      .btn {
        margin: 2em auto 1em;
      }

      #other-data {
        width: 100%;
        flex-flow: column;
        margin: 1rem;

        .container-column {
          width: 100%;
          margin: 0 auto;
          padding: 0;
        }

      }
    
    }

    
  }

  #cont-admin {
    .user {
      flex-flow: row nowrap;
      max-width: unset;

    }
  }

  #editar {
    #theme {
      margin: 1em 0;
      .container-row {
        flex-flow: row nowrap;
        margin: 1em 0;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  #table {
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 0 1em;

    .botones-tabla {
      width: fit-content;
      margin: 0 auto;
    }

    table {
      display: block;
      margin: 1em auto;
      padding: 0.5em;
      width: 100%;
      tbody {
        display: block;
        width: 100%;
        tr {
          border: 1px solid $gray;
          border-radius: 0.25rem;
          width: 100%;
          display: block;
          padding: 0.5rem;
  
          td {
            margin: auto;
            width: 100%;
            display: block;
          }

          #opc-cont {
            &::before {
              content: unset;
            }
  
            .container-row {
              flex-flow: row nowrap;
              margin: 0;
            }
          }
        }
      }
    }
  }

  #header-app {
    padding: 0 $padding;
  }

  thead {
    display: none;
  }

  #app .boton {
    min-width: unset;
    width: 70%;
  }

  #admin {
    padding: 1rem;
  }

  tr {
    display: block;
    width: fit-content;
    min-width: 50vw;
    padding: 0;
    text-align: left;
    margin-bottom: 1em;
  }

  td {
    display: block;
    min-width: fit-content;
    max-width: fit-content;
    text-align: left;

    &:before {
      content: attr(data) ": ";
      font-weight: bold;
      width: 10em;
      display: inline-block;
    }
  }

  table {
    display: inline-block;
    min-width: fit-content;
    padding: 0;
    margin-top: 0.5em;
    border: 0;
    width: fit-content;
  }
}

@media only screen and (max-width: 600px) {
  header {
    flex-flow: column;
  }

  .visible {
    display: flex;
  }

  .invisible {
    display: none;
  }

  /* A LITTLE BUT ESSENTIAL CHANGE FOR THOSE LETTERS */

  form a, form span {
    font-weight: 600;
  }

  /* HEADER */

  #lista {
    display: initial;
    margin: auto;
    margin-left: auto;
    margin-right: 1em;
    font-size: 1em;
    cursor: pointer;
  }

  .responsive {
    display: flex;
    flex-flow: row;
    width: 100%;
  }

  .responsive a {
    margin-top: auto;
  }

  header img {
    height: 64px;
  }

  /* NAVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV */

  nav {
    display: none;
    flex-flow: column wrap;
    margin: 1em 0;
    margin-right: auto;
    text-align: left;
    width: 80vw;

    a {
      width: 100%;
    }
  }

  header a:not(.perfil) {
    margin-right: auto;
  }

  /* LOGIN / REGISTER */

  #login, #register {
    margin: 10vh auto;
  }

  #background {
    height: 100vh;
    width: 100vw;
  }

  #background img {
    transform: rotate(270deg);
    opacity: 0.85;
    width: auto;
  }

  #perfil-cont {
    
    .container-row {
      flex-flow: column;
    }
    .container-column {
      #role {
        flex-flow: row;
      }

      #other-data {
        width: 100%;
        margin: 0;
  
        .container-column {
          //justify-content: space-between;
          width: fit-content;
          flex-flow: row;
          align-items: center;
          margin: auto;
          
          .label {
            width: fit-content;
            margin: 1em;
            //width: 40%;
          }

          p {
            //width: 60%;
            text-align: left;
            margin-right: auto;
          }
        }
      }
    }
  }
}
