$primary-color: #ff5722;
$primary-color-hover: #ee3900;
$black: #1b1f24;
$black-hover: #050607;
$dark: #262B32;
$gray: transparentize(#acacac, 0.4);
$white: #fdfdfd;
$red: #a94442;
$danger: #c91d12;
$lightred: #f2dede;
$padding: 4vw;
$code: #40e0d0;
$heart: purple;